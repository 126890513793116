import React from 'react';

const SafeguardList = React.lazy(() => import('pages/Safeguard'));
// const SafeGuardForm = React.lazy(() => import('pages/Safeguard/form'));
const CFPList = React.lazy(() => import('pages/CFP'));
const CFPReview = React.lazy(() => import('pages/CFP/Review'));
const CFPCommitment = React.lazy(() => import('pages/CFP/Commitment'));
const Commitment = React.lazy(() => import('pages/Commitment'));
const DetailCommitment = React.lazy(() => import("pages/Commitment/detail"));
const FinalizationCommitment = React.lazy(() => import("pages/CFP/Finalization"));
const ReviewCommitment = React.lazy(() => import('pages/Commitment/review'));

const routesName = [
  {
    routeType: 'private',
    path: '/',
    element: SafeguardList,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/safeguard',
    element: SafeguardList,
    exact: true,
  },
  // {
  //   routeType: 'private',
  //   path: '/safeguard/new',
  //   element: SafeGuardForm,
  //   exact: true,
  // },
  // {
  //   routeType: 'private',
  //   path: '/safeguard/:id/edit',
  //   element: SafeGuardForm,
  //   exact: true,
  // },
  // {
  //   routeType: 'private',
  //   path: '/safeguard/:id/detail',
  //   element: SafeGuardForm,
  //   exact: true,
  // },
  {
    routeType: 'private',
    path: '/cfp',
    element: CFPList,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/cfp/:id/review',
    element: CFPReview,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/cfp/:id/commitment',
    element: CFPCommitment,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/cfp/:id/financialization-commitment',
    element: FinalizationCommitment,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/commitment',
    element: Commitment,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/commitment/:id',
    element: DetailCommitment,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/commitment/:id/review',
    element: ReviewCommitment,
    exact: true,
  },
];

export default routesName;
